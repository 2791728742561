import React, { forwardRef} from "react"
import styled from "styled-components";
import LinkedIn from "./icons8-linkedin.svg"
import Email from "./icons8-mail.svg"

import Pdfr from "./projects/Paper.pdf"
import Pdfcv from "./projects/CV_en.pdf"


const openLinkInNewTab = ( url ) => {
  const newTab = window.open(url, '_blank', 'noopener,noreferrer');
  if ( newTab ) newTab.opener = null;
}

const openEmailInNewTab = () => {
  const newTab = window.open('mailto:OliverClaassen@icloud.com')
  if ( newTab ) newTab.opener = null;
}

function openTab(th)
{
    window.open(th.name,'_blank');
}

const Button = styled.button`
  transform-origin: center;
  background-color: transparent;
  height: 48px;
  width: 48px;
  object-fit: stretch;
  color: white;
  padding: 0;
  border: none;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: ease 250ms;
  img{
    transform-origin: center;
  }
`;
const Overlay = forwardRef(({ caption, scroll }, ref) => (
  
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
      caption.current.innerText = scroll.current.toFixed(2)
    }}
    className="scroll">
    <div style={{ height: "200vh" }}>
      <div className="dotSpecial">
        <h1>Scroll down</h1>
      </div>
    </div>
    <div style={{height: "100vh"}}>
    </div>
    <div style={{ height: "190vh" }}>
      <div className="dot">
        <h1>Hi!</h1>
          <p>I'm Oliver Claassen, I'm a 26 year old Engineering Psychologist, Researcher & Human Computer Interaction designer. <b>Formerly @ Mercedes Benz.</b> </p>
      </div>
    </div>
    <div style={{height: "20vh"}}>
    </div>
    <div style={{ height: "150vh" }}>
      <div className="dot">
        <h1>Passion.</h1>
        <p>I believe that, psychologists and engineers can work together to unpack creative
        ways to design systems that better serve the way we <b>think, feel & play!</b> By combining design with Research, Mental Models & Human Factors i've helped systems lead to less inclusive barriers, injuries, lowering costs and increasing productivity.</p>
      </div>
    </div>
    <div style={{ height: "110vh" }}>
      <div className="dot">
        <h1>Education.</h1>
        <b><i>Jun. 2023 </i>Human Computer Interaction:</b> <br /> Master of Engineering (Language: English)<br></br>
        <b><i>Mar. 2021 </i>Engineering psychology:</b> <br /> Bachelor of Science in Engineering (Language: German)<br />
        <p><button class="button-21" role="button"><a href = {Pdfcv} target = "_blank">Full CV</a></button></p>

      </div>
    </div>
    <div style={{height: "180vh"}}>
    </div>
    <div style={{ height: "180vh" }}>
      <div className="dot">
        <h1>Work Experience.</h1>
        <b><i>~1 year </i>Professional UX Consultant @ ERNI</b><br /> Zürich, Swizerland<br />
        <b><i>~5 years </i>UX/UI Designer @ Mercedes-Benz AG</b> <br /> Sindelfingen, Germany<br />
        <b><i>~8 Years </i>Freelancing</b> <br /> UX, UI & 3D Design for Games & Apps | Motion Design
      </div>
    </div>
    <div style={{height: "130vh"}}>
      <div className="dot">
        <h1>Projects & Research</h1>
        <p><button class="button-21" role="button"><a href = {Pdfr} target = "_blank">Open</a></button> Research</p>
        <p><button class="button-21" role="button"><a>Coming soon!</a></button> Design Concepts</p>
      </div>
    </div>
    <div style={{ height: "180vh" }}>
      <div className="dot">
        <h1>Certificates.</h1>
        <p>1. Place @ UX Challenge Hackathon <br />
        <b>Accenture X Steinbeis Europe </b></p>
        <p>1. Place @ Innovation Hackathon<br />
        <b>Innovation Days Salzburg</b></p>
      </div>
    </div>
    <div style={{height: "150vh"}}>
    </div>
    <div style={{ height: "100vh" }}>
      <div className="dotContact">
        <h1>Say hello!
        </h1>
        <p>Feel free to contact me for more information about me, my work & my skills!</p>
        <Button onClick={ () => openLinkInNewTab('https://www.linkedin.com/in/oliver-claassen-7a264618b/')}>
          <img src={LinkedIn} alt="LinkedIn Contact Linkout"/>
        </Button>
        <Button onClick={ () => openEmailInNewTab()}>
          <img src={Email} alt="Email Contact Linkout"/>
        </Button>
      </div>
    </div>
    <span className="caption" ref={caption}>
      0.00
    </span>
  </div>
))

export default Overlay
