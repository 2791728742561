import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei"
import { useFrame, useThree } from "@react-three/fiber"

export default function Model({ scroll, ...props }) {

  const group = useRef()
  const { nodes, materials, animations } = useGLTF("/updateresolution.glb")
  const { actions } = useAnimations(animations, group)
  const { size } = useThree()
  const fov = clamp(size.width, 360, 1400, 60, 25);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
    return ()=> document.body.style.cursor = 'auto';
  }, [hovered])


  useEffect(() => void (actions["CamAction"].play().paused = true), [])
  useFrame((state) => {
    actions["CamAction"].time = THREE.MathUtils.lerp(actions["CamAction"].time, actions["CamAction"].getClip().duration * scroll.current, 0.08)
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
      <group name="OverallParent" position={[0.06, 4.04, 0.35]} scale={0.25}>
          <group
            name="Clipboardfbx"
            position={[22.58, 9.03, 6.61]}
            rotation={[Math.PI, -0.68, Math.PI]}
            scale={0.26}
          >
            <group name="RootNode"
              castShadow
              receiveShadow
              >
              <group
                name="Clipboard_low"
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[100, 100, 60]}
              >
                <mesh
                  name="Clipboard_low_ClipBoard_0"
                  geometry={nodes.Clipboard_low_ClipBoard_0.geometry}
                  material={materials.ClipBoard}
                />
              </group>
            </group>
          </group>
          <group
            name="clipboardParent"
            position={[22.58, 9.03, 6.61]}
            rotation={[-Math.PI / 2, 0, -2.46]}
            scale={25.75}
          />
          <group
            name="Macbook"
            position={[-9.17, 9.04, 22.51]}
            rotation={[-Math.PI / 2, 0, 2.69]}
            scale={3.99}
          >
            <group name="root">
              <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
                <group name="Cube_5">
                  <group name="Cube008_0" position={[-1.45, 0.09, -0.57]}>
                    <mesh
                      name="Object_15"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_15.geometry}
                      material={materials["Material.011"]}
                    />
                  </group>
                  <group
                    name="Cube016_2"
                    position={[0, 0.1, -1.01]}
                    rotation={[-1.92, 0, 0]}
                  >
                    <group
                      name="Curve_1"
                      position={[-0.03, 0.62, 1.02]}
                      rotation={[Math.PI, 0, Math.PI]}
                      scale={[32.94, 32.94, 33.49]}
                    >
                      <mesh
                        name="Object_23"
                        castShadow
                        receiveShadow
                        geometry={nodes.Object_23.geometry}
                        material={materials["Material.017"]}
                      />
                    </group>
                    <mesh
                      name="Object_17"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_17.geometry}
                      material={materials["Material.003"]}
                    />
                    <mesh
                      name="Object_18"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_18.geometry}
                      material={materials["Material.007"]}
                    />
                    <mesh
                      name="Object_19"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_19.geometry}
                      material={materials["Material.006"]}
                    />
                  </group>
                  <group
                    name="Cylinder001_4"
                    position={[-1.49, 0.09, -0.76]}
                    rotation={[0, 0, -Math.PI / 2]}
                    scale={-0.01}
                  >
                    <mesh
                      name="Object_28"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_28.geometry}
                      material={materials["Material.010"]}
                    />
                  </group>
                  <group name="Cylinder_3">
                    <mesh
                      name="Object_25"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_25.geometry}
                      material={materials["Material.003"]}
                    />
                    <mesh
                      name="Object_26"
                      castShadow
                      receiveShadow
                      geometry={nodes.Object_26.geometry}
                      material={materials["Material.014"]}
                    />
                  </group>
                  <mesh
                    name="Object_10"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_10.geometry}
                    material={materials["Material.002"]}
                  />
                  <mesh
                    name="Object_11"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_11.geometry}
                    material={materials["Material.002"]}
                  />
                  <mesh
                    name="Object_12"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_12.geometry}
                    material={materials["Material.009"]}
                  />
                  <mesh
                    name="Object_13"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_13.geometry}
                    material={materials["Material.009"]}
                  />
                  <mesh
                    name="Object_4"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_4.geometry}
                    material={materials["Material.003"]}
                  />
                  <mesh
                    name="Object_5"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_5.geometry}
                    material={materials["Material.013"]}
                  />
                  <mesh
                    name="Object_6"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_6.geometry}
                    material={materials["Material.016"]}
                  />
                  <mesh
                    name="Object_7"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_7.geometry}
                    material={materials["Material.004"]}
                  />
                  <mesh
                    name="Object_8"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_8.geometry}
                    material={materials["Material.002"]}
                  />
                  <mesh
                    name="Object_9"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_9.geometry}
                    material={materials["Material.002"]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group
            name="Sketchfab_model"
            position={[-24.47, 9.71, 4.73]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={3.99}
          >
            <group name="root001"
            receiveShadow
            castShadow>
              <group
                name="GLTF_SceneRootNode001"
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group
                  name="Cube001_3"
                  position={[0, 0.3, 0]}
                  rotation={[0, 0.55, 0]}
                >
                  <mesh
                    name="Object_8001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_8001.geometry}
                    material={materials.Pages}
                  />
                  <mesh
                    name="Object_7001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_7001.geometry}
                    material={materials.Book2}
                  />
                </group>
                <group name="Cube_0">
                  <mesh
                    name="Object_5001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_5001.geometry}
                    material={materials.Pages}
                  />
                  <mesh
                    name="Object_4001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_4001.geometry}
                    material={materials.Book1}
                  />
                </group>
                <group name="Cylinder001_4001" position={[0, 1.05, 0]}>
                  <mesh
                    name="Object_10001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_10001.geometry}
                    material={materials.material}
                  />
                </group>
                <group name="Vert001_6" position={[0, 1.07, 0]}>
                  <mesh
                    name="Object_12001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_12001.geometry}
                    material={materials.Rope}
                  />
                  <mesh
                    name="Object_13001"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_13001.geometry}
                    material={materials.Bead}
                  />
                </group>
              </group>
            </group>
          </group>
          <mesh
            name="Name"
            castShadow
            receiveShadow
            geometry={nodes.Name.geometry}
            material={materials.Name}
            position={[24.97, 9.26, -102.14]}
            rotation={[Math.PI / 2, 0, 2.59]}
            scale={954.94}
          />
          <mesh
            name="VR"
            castShadow
            receiveShadow
            geometry={nodes.VR.geometry}
            material={materials.VR_googles}
            position={[14.25, 10.49, 21.19]}
            rotation={[1.7, 0, 0]}
            scale={0.68}
          />
          <mesh
            name="EyeLeft"
            castShadow
            receiveShadow
            geometry={nodes.EyeLeft.geometry}
            material={materials.Wolf3D_Eye}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="EyeRight"
            castShadow
            receiveShadow
            geometry={nodes.EyeRight.geometry}
            material={materials.Wolf3D_Eye}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Body"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Body.geometry}
            material={materials.Wolf3D_Body}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Glasses"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Glasses.geometry}
            material={materials.Wolf3D_Glasses}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Hair"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Hair.geometry}
            material={materials.Wolf3D_Hair}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Head"
            geometry={nodes.Wolf3D_Head.geometry}
            material={materials.Wolf3D_Skin}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Outfit_Bottom"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
            material={materials.Wolf3D_Outfit_Bottom}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Outfit_Footwear"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
            material={materials.Wolf3D_Outfit_Footwear}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
          <mesh
            name="Wolf3D_Outfit_Top"
            castShadow
            receiveShadow
            geometry={nodes.Wolf3D_Outfit_Top.geometry}
            material={materials.Wolf3D_Outfit_Top}
            position={[-0.25, -16.15, -1.38]}
            scale={3.99}
          />
        </group>

        <group name="Camera" position={[37.22, 7.75, 45.28]} rotation={[Math.PI / 2, 0, -0.59]}>
          <PerspectiveCamera makeDefault far={500} near={0.1} fov={(fov)} rotation={[-Math.PI / 2, 0, 0]}></PerspectiveCamera>
        </group>
      </group>
    </group>
    
  )
}

function clamp(size, A, B, C, D){
  if(((size-A)*(D-C)/(B-A) + C) < 25)
  {
    return 25
  }
  else if(((size-A)*(D-C)/(B-A) + C) > 75)
  {
    return 75
  }
  return (size-A)*(D-C)/(B-A) + C;
}




useGLTF.preload("/updateresolution.glb")
