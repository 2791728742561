import React, { Suspense, useRef, useState } from "react"
import { Environment, Loader } from "@react-three/drei"
import { Canvas, useFrame } from "@react-three/fiber"

import Me from "./Me"
import Skateboard from "./Skateboard"
import Overlay from "./Overlay"
import { DoubleSide } from "three"

import warehouse from "./warehouse.hdr"
//@ts-check
//This is working in GIT


function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame

  useFrame((state, delta) => (ref.current.rotation.x += delta))
  useFrame((state, delta) => (ref.current.rotation.y += delta))

  
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
      castShadow={true}
      receiveShadow={true}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}


export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  const [down, set] = useState(false)


  return (
    <>
      <Canvas
      shadows
      onCreated={(state) => state.events.connect(overlay.current)}
      raycaster={{
          computeOffsets: ({ offsetX, offsetY }) => ({
            offsetX,
            offsetY: offsetY - state.top
          })
        }}>
        <color attach="background" args={['white']} />
        <Suspense fallback={null}>1
          <ambientLight intensity={0} />
            <directionalLight
              castShadow
              color={"hotpink"}
              position={[-10, 15, -4]}
              intensity={2}
              shadow-mapSize-width={512}
              shadow-mapSize-height={512}
              shadow-camera-far={100}
              shadow-camera-left={-15}
              shadow-camera-right={20}
              shadow-camera-top={20}
              shadow-camera-bottom={-20}
            />
            <directionalLight
              color={"white"}
              position={[10, 15, 10]}
              intensity={1.1}
            />
            <directionalLight
              color={"pink"}
              position={[-105, 50, -180]}
              intensity={0.1}
            />
            <Me  scroll={scroll} />
            <Skateboard castShadow receiveShadow/>
            <mesh catchShadow receiveShadow  position={[-10, 6.33, 2]} rotation={[Math.PI / 2, 0, 0]} scale={[360, 300, 300]}>
              <planeGeometry />
              <shadowMaterial 
                color="black"
                opacity={0.25}
                side={DoubleSide} 
                roughness={1}
                metalness={0} />
            </mesh>
            <Environment files={warehouse}>
            </Environment>
        <>
        </>
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
      <Loader />
    </>
  )
}