/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from 'react'
import { useCursor, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import { Color } from 'three';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/skateboard.glb')
  const group = useRef();
  const [hovered, setHovered] = useState(false);
  const [jump, setJump] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
    return ()=> document.body.style.cursor = 'auto';
  }, [hovered])
  
  return (
    <group {...props} dispose={null} castShadow receiveShadow 
      //onPointerOver={(e) => setHovered(true)}
      //onPointerOut={(e) => setHovered(false)}
      //npmonClick={(e) => setActive(!jump)}
      >
      <group ref={group} position={[0, 7.2, -4]} rotation={[Math.PI/1 , Math.PI+0.1, 0]} scale={0.09}>
      <mesh geometry={nodes.Object_6.geometry} material={materials.skatie} scale={[1, 1.06, 1]} castShadow/>
      <mesh geometry={nodes.Object_10.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_13.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_16.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_19.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_22.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_25.geometry} material={materials.skatie} position={[0.4, 0, -25.48]} rotation={[Math.PI, 0, 0]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_29.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_32.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_35.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_38.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_41.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_44.geometry} material={materials.skatie} position={[0.37, 0, -25.48]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.32} castShadow/>
      <mesh geometry={nodes.Object_47.geometry} material={materials.skatie} castShadow/>
      <mesh geometry={nodes.Object_50.geometry} material={materials.skatie} castShadow/>
      <mesh geometry={nodes.Object_54.geometry} material={materials.skatie} position={[-20.34, 7.86, 5.06]} castShadow/>
      <mesh geometry={nodes.Object_56.geometry} material={materials.skatie} position={[-20.33, -7.75, 5.07]} rotation={[0, -1.1, -Math.PI]} castShadow/>
      <mesh geometry={nodes.Object_59.geometry} material={materials.skatie} position={[-20.33, 9.06, 5.09]} castShadow/>
      <mesh geometry={nodes.Object_61.geometry} material={materials.skatie} position={[-20.35, -8.95, 5.07]} rotation={[0, -1.1, -Math.PI]} castShadow/>
      <mesh geometry={nodes.Object_65.geometry} material={materials.skatie} position={[21.1, 7.86, 5.07]} rotation={[0, 1.54, 0]} castShadow/>
      <mesh geometry={nodes.Object_67.geometry} material={materials.skatie} position={[21.1, -7.75, 5.08]} rotation={[Math.PI, 1.54, 0]} castShadow/>
      <mesh geometry={nodes.Object_70.geometry} material={materials.skatie} position={[21.12, 9.06, 5.07]} rotation={[0, 1.54, 0]} castShadow/>
      <mesh geometry={nodes.Object_72.geometry} material={materials.skatie} position={[21.12, -8.95, 5.08]} rotation={[Math.PI, 1.54, 0]} castShadow/>
      </group>
    </group>
  )
}

useGLTF.preload('/skateboard.glb')
